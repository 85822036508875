import Vue from 'vue'
import Vuex from 'vuex'
import Api from '@/common/api.service'

Vue.use(Vuex)

const state = {
  typesCombats: [],
  combats: [],
  userIds: [],
  users: {}
}

const actions = {
  async fetchCombats ({ dispatch }) {
    await dispatch('fetchCombatsType')
    await dispatch('fetchCombatsList')
    await dispatch('fetchUsers')
  },
  async fetchCombatsType ({ commit }) {
    await Api.query('arena/combat-type')
      .then(data => {
        commit('SET_TYPES_COMBATS', data)
      })
      .catch(() => {
        console.error('get combat type error')
      })
  },
  async fetchCombatsList ({ commit }) {
    await Api.query('arena/combat')
      .then(data => {
        const userIds = []
        data.forEach(item => {
          JSON.parse(item.recipient_team).forEach(userId => {
            userIds[userId] = userId
          })
          JSON.parse(item.sender_team).forEach(userId => {
            userIds[userId] = userId
          })
        })

        commit('SET_COMBATS', data)
        commit('SET_USER_IDS', Object.values(userIds))
      })
      .catch(() => {
        console.error('get combats error')
      })
  },
  async updateCombat ({ commit, getters, dispatch }, combat) {
    await Api.put(process.env.VUE_APP_API_URL + 'arena/combat/' + combat.id, combat.data)
      .then(async function (response) {
        await dispatch('fetchCombatsList')
      })
  },
  async fetchUsers ({ commit }) {
    const usersInfo = {}

    await Api.query(process.env.VUE_APP_API_URL + 'bitrix24/call/user.get', {
      sort: 'ID',
      order: 'ASC',
      FILTER: { USER_TYPE: 'employee', ID: state.userIds },
      ADMIN_MODE: 1
    })
      .then(data => {
        data = data.result

        data.forEach(el => {
          usersInfo[el.ID] = {
            id: parseInt(el.ID),
            name: el.NAME + ' ' + el.LAST_NAME,
            post: el.WORK_POSITION,
            department: el.UF_DEPARTMENT[0] || 0,
            avatar: el.PERSONAL_PHOTO
          }
        })

        commit('SET_USERS_INFO', usersInfo)
      })
      .catch(() => {
        console.error('get users error')
      })
  }
}

const mutations = {
  SET_TYPES_COMBATS (state, data) {
    state.typesCombats = data
  },
  SET_COMBATS (state, data) {
    state.combats = data
  },
  UPDATE_COMBAT_STATUS (state, data) {
    state.combats = data
  },
  SET_USER_IDS (state, data) {
    state.userIds = data
  },
  SET_USERS_INFO (state, data) {
    // const users = []
    // for (const userId in data) {
    //   users.push(data[userId])
    // }
    // state.users = users

    state.users = data
  }
}

const getters = {
  allCombats (state, getters, rootState) {
    return state.combats.map(combat => {
      return {
        id: combat.id,
        type_id: combat.type_id,
        goal: combat.goal,
        deadline: new Date(combat.deadline).getTime(),
        status: combat.status,
        sender_team: JSON.parse(combat.sender_team),
        recipient_team: JSON.parse(combat.recipient_team),
        winning_team: combat.winning_team,
        losing_team: combat.losing_team,
        title: JSON.parse(combat.sender_team).indexOf(rootState.auth.user.bitrix24_user_id) !== -1 ? JSON.parse(combat.sender_team).length + 'x' + JSON.parse(combat.recipient_team).length : JSON.parse(combat.recipient_team).length + 'x' + JSON.parse(combat.sender_team).length + ': ' + state.typesCombats.find(el => el.id === combat.type_id).title,
        enemy: JSON.parse(combat.sender_team).indexOf(rootState.auth.user.bitrix24_user_id) !== -1 ? JSON.parse(combat.recipient_team) : JSON.parse(combat.sender_team)
      }
    })
  },
  activeCombats (state, getters) {
    return getters.allCombats.filter(combat => combat.status === 'accepted')
  },
  invitationCombats (state, getters) {
    return getters.allCombats.filter(combat => combat.status === 'invitation')
  },
  users (state) {
    return state.users
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
