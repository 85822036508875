<template>
  <div @click="$root.globalClick">
    <component :is="component" v-bind="params"></component>
  </div>
</template>

<script>
export default {
  name: 'Panel',
  props: ['component', 'params']
}
</script>
