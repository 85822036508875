export default [
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Index.vue'),
    redirect: '/settings/general',
    children: [
      {
        path: 'general',
        name: 'settings/general',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/General.vue')
      },
      {
        path: 'rights',
        name: 'settings/rights',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Rights.vue'),
        meta: {
          perm: 'edit_rights',
          title: 'Сотрудники и роли'
        }
      }
    ]
  },
  {
    path: '/integrations',
    name: 'integrations',
    component: () => import(/* webpackChunkName: "integrations" */ '@/views/settings/integrations/Index.vue'),
    children: [
      {
        path: 'bitrix24',
        name: 'integrations/bitrix24',
        component: () => import(/* webpackChunkName: "integrations" */ '@/views/settings/integrations/Bitrix24.vue'),
        meta: {
          id: 'bitrix24'
        }
      },
      {
        path: 'modulbank',
        name: 'integrations/modulbank',
        component: () => import(/* webpackChunkName: "integrations" */ '@/views/settings/integrations/Modulbank.vue'),
        meta: {
          id: 'modulbank'
        }
      }
    ]
  },
  {
    path: '/doc-templates',
    name: 'doc-templates',
    component: () => import(/* webpackChunkName: "doc-templates" */ '@/views/settings/doc-templates/Index.vue'),
    children: [
      {
        path: ':id',
        name: 'doc-templates/detail',
        component: () => import(/* webpackChunkName: "doc-templates" */ '@/views/settings/doc-templates/Detail.vue'),
        meta: {
          isPanel: true,
          panelTitle: 'Шаблон #{id}',
          panelSize: 'xl'
        }
      }
    ]
  }
]
