<template>
  <div
    :id="`icon_${id}`"
    class="sprite_icon"
    :class="[icon, hover ? 'hover' : '']"
    @click="$emit('icon-click')"
  >
    <svg><use :xlink:href="`${spriteSvg}#${icon}`"></use></svg>
    <b-tooltip
      v-if="tooltipText.length > 0"
      :target="`icon_${id}`"
      :title="tooltipText"
    />
  </div>
</template>

<script>
import spriteSvg from '@/assets/svg/sprite.svg'

export default {
  name: 'SpriteIcon',
  props: {
    icon: {
      type: String,
      default: 'success'
    },
    tooltipText: {
      type: String,
      default: ''
    },
    hover: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      spriteSvg,
      id: this._uid
    }
  }
}
</script>

<style scoped>
.sprite_icon {
  display: inline-block;
}
.sprite_icon.sm {
  width: 20px;
  height: 20px;
}
.sprite_icon.lg {
  width: 28px;
  height: 28px;
}
.sprite_icon.hover:hover svg {
  color: var(--text-color);
}
</style>
