import bitrix24 from './bitrix24.module'

const state = {}
const actions = {}
const mutations = {}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    bitrix24
  }
}
