import Api from '@/common/api.service'

const state = {
  isSyncing: false,
  count: 0,
  total: 0,
  error: ''
}
const mutations = {
  START_SYNCING (state) {
    state.isSyncing = true
  },
  STOP_SYNCING (state) {
    state.isSyncing = false
  },
  SET_COUNT (state, count) {
    state.count = count
  },
  SET_TOTAL (state, total) {
    state.total = total
  }
}
const actions = {
  async sync ({ commit, state }, payload) {
    if (state.isSyncing) {
      return
    }
    await commit('START_SYNCING')
    if (payload.start) {
      await payload.start()
    }

    let count = 0
    let start = 0
    let total = 0
    let result
    do {
      try {
        const startTime = Date.now()

        result = await Api.query('bitrix24/sync/' + payload.entity, { start })
        start = result.next
        count += result.count
        total = result.total

        await commit('SET_COUNT', count)
        await commit('SET_TOTAL', total)

        if (payload.step) {
          await payload.step({ count, total })
        }

        if (Date.now() - startTime < 550) {
          await new Promise(resolve => setTimeout(resolve, 550 - (Date.now() - startTime)))
        }
      } catch (err) {
        if (payload.error) {
          await payload.error(err)
        }
        await commit('STOP_SYNCING')
        return
      }
    } while (result.next)

    await commit('STOP_SYNCING')
    if (payload.finish) {
      await payload.finish()
    }
  }
}
const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
