import Vue from 'vue'
import VueRouter from 'vue-router'

import SettingsRoutes from './settings.module'
import SaleRoutes from './sale.module'
import FinRoutes from './fin.module'
import CallRoutes from './call.module'

Vue.use(VueRouter)

let routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/sale'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    meta: {
      title: 'Авторизация'
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '@/views/Logout.vue')
  }
]

if (process.env.NODE_ENV === 'development') {
  routes.push({
    path: '/styleguide',
    name: 'styleguide',
    component: () => import(/* webpackChunkName: "styleguide" */ '@/views/Styleguide.vue')
  })
}

routes = routes.concat(SettingsRoutes, SaleRoutes, FinRoutes, CallRoutes)

routes.push({
  path: '/:catchAll(.*)',
  name: 'notfound',
  component: () => import(/* webpackChunkName: "notfound" */ '@/views/Notfound.vue')
})

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  routes
})

export default router
