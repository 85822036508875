export const APP_PREFIX = 'ranx.bigame_'

export const APP_FIN = 'fin'
export const APP_SALE = 'sale'
export const APP_CALL = 'call'

export const APP_PAGES = {
  [APP_FIN]: '/fin',
  [APP_SALE]: '/sale',
  [APP_CALL]: '/call'
}
