import moment from 'moment'

const state = {
  date: new Date()
}

const mutations = {
  SET_DATE (state, newDate) {
    state.date = newDate
  }
}

const actions = {
  async updateDate ({ commit }, newDate) {
    await commit('SET_DATE', newDate)
  }
}

const getters = {
  isPresentMonth: state => {
    return (state.date.getFullYear() === (new Date()).getFullYear()) && (state.date.getMonth() === (new Date()).getMonth())
  },
  isPreviousDate: state => {
    if (
      (state.date.getFullYear() < (new Date()).getFullYear()) ||
      ((state.date.getFullYear() === (new Date()).getFullYear()) && (state.date.getMonth() < (new Date()).getMonth()))
    ) {
      return true
    } else {
      return false
    }
  },
  dateFormatted: state => {
    return moment(state.date).format('D.MM.YYYY')
  },
  dateQuery: (state, getters) => {
    return !getters.isPresentMonth ? `?date=${getters.dateFormatted}` : ''
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
