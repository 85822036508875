import settings from './settings.module'
import date from './date.module'

const state = {}
const actions = {}
const mutations = {}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    settings,
    date
  }
}
