import { APP_FIN } from '@/common/b24.apps'

export default [
  {
    path: '/fin',
    name: 'fin',
    component: () => import(/* webpackChunkName: "fin" */ '@/views/fin/Index.vue'),
    meta: {
      app: APP_FIN,
      perm: 'fin_module'
    },
    children: [
      {
        path: 'operations',
        name: 'fin/operations',
        component: () => import(/* webpackChunkName: "fin" */ '@/views/fin/Operations.vue'),
        meta: {
          perm: 'fin_operation_view'
        },
        children: [
          {
            path: ':id',
            name: 'fin/operations/detail',
            component: () => import(/* webpackChunkName: "fin" */ '@/views/fin/OperationEdit.vue'),
            meta: {
              isPanel: true,
              panelSize: 'xl',
              panelTitle: 'Транзакция #{id}'
            }
          }
        ]
      },
      {
        path: 'invoices',
        name: 'fin/invoices',
        component: () => import(/* webpackChunkName: "fin" */ '@/views/fin/Invoices.vue'),
        meta: {
          perm: 'fin_invoice_view'
        },
        children: [
          {
            path: 'create',
            name: 'fin/invoices/create',
            component: () => import(/* webpackChunkName: "fin" */ '@/views/fin/InvoiceEdit.vue'),
            meta: {
              isPanel: true,
              panelSize: 'xxl',
              panelTitle: 'Новый счет'
            }
          },
          {
            path: ':id',
            name: 'fin/invoices/detail',
            component: () => import(/* webpackChunkName: "fin" */ '@/views/fin/InvoiceEdit.vue'),
            meta: {
              isPanel: true,
              panelSize: 'xxl',
              panelTitle: 'Счет #{id}'
            }
          }
        ]
      },
      {
        path: 'cashflow',
        name: 'fin/cashflow',
        component: () => import(/* webpackChunkName: "fin" */ '@/views/fin/Cashflow.vue'),
        meta: {
          perm: 'fin_cashflow'
        }
      }
    ]
  },
  {
    path: '/fin/settings',
    name: 'fin/settings',
    component: () => import(/* webpackChunkName: "fin" */ '@/views/fin/settings/Index.vue'),
    meta: {
      app: APP_FIN,
      perm: 'fin_settings'
    },
    children: [
      {
        path: 'categories',
        name: 'fin/settings/categories',
        component: () => import(/* webpackChunkName: "fin" */ '@/views/fin/settings/CategoryList.vue')
      },
      {
        path: 'orgs',
        name: 'fin/settings/orgs',
        component: () => import(/* webpackChunkName: "fin" */ '@/views/fin/settings/Orgs.vue'),
        children: [
          {
            path: ':id',
            name: 'fin/settings/orgs/detail',
            component: () => import(/* webpackChunkName: "fin" */ '@/views/fin/settings/OrgEdit.vue'),
            meta: {
              isPanel: true,
              panelTitle: 'Организация #{id}'
            }
          }
        ]
      },
      {
        path: 'org-invoices',
        name: 'fin/settings/org-invoices',
        component: () => import(/* webpackChunkName: "fin" */ '@/views/fin/settings/OrgInvoices.vue'),
        children: [
          {
            path: ':id',
            name: 'fin/settings/org-invoices/detail',
            component: () => import(/* webpackChunkName: "fin" */ '@/views/fin/settings/OrgInvoiceEdit.vue'),
            meta: {
              isPanel: true,
              panelTitle: 'Счет организации #{id}'
            }
          }
        ]
      }
    ]
  }
]
