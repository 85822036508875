import Api from '@/common/api.service'

const state = {
  entity: '',
  deal_mode: '',
  paid_deal_stage_name: '',
  predict_deal_stage_name: '',
  paid_deal_date: '',
  predict_deal_date: '',
  measure: '',
  segment: '',
  planning_mode: '',
  min_sum_mode: '',
  copy_plan: '',
  deal_sum: ''
}
const actions = {
  async fetchSettings ({ commit }) {
    await Api.query('option?module=sale').then(response => {
      commit('SET_OPTIONS', response)
    })
  }
}
const mutations = {
  SET_OPTIONS (state, data) {
    for (const code in data) {
      const value = data[code]
      const stateCode = code.replace('sale_', '')

      state[stateCode] = value
    }
  }
}
const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
