<template>
  <div class="d-flex justify-content-center align-items-center" style="min-height: 200px;">
    <div class="loader">
      <svg class="loader-svg" viewBox="25 25 50 50">
        <circle class="loader-svg-circle" cx="50" cy="50" r="20" fill="none" stroke-miterlimit="10"></circle>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<style scoped>
.loader {
  width: 100px;
  height: 100px;
}
.loader-svg {
  animation: rotateMainLoader 2s linear infinite;
  transform-origin: center center;
}
.loader-svg-circle {
  stroke: #bfc3c8;
  stroke-width: 1.5;
  stroke-dasharray: 20,200;
  stroke-dashoffset: 0;
  animation: animateMainLoaderDash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}
@keyframes rotateMainLoader {
 100% {
  transform:rotate(360deg)
 }
}
@keyframes animateMainLoaderDash {
 0% {
  stroke-dasharray:1,200;
  stroke-dashoffset:0
 }
 50% {
  stroke-dasharray:89,200;
  stroke-dashoffset:-35px
 }
 100% {
  stroke-dasharray:89,200;
  stroke-dashoffset:-124px
 }
}
</style>
