import { APP_SALE } from '@/common/b24.apps'

export default {
  path: '/sale',
  name: 'sale',
  component: () => import(/* webpackChunkName: "sale" */ '@/views/sale/Index.vue'),
  meta: {
    app: APP_SALE,
    perm: 'sale_module'
  },
  children: [
    {
      path: 'plan',
      name: 'sale/plan',
      component: () => import(/* webpackChunkName: "plan" */ '@/views/sale/plan/Index.vue'),
      redirect: { name: 'sale/plan/personal' },
      meta: {
        title: 'План продаж'
      },
      children: [
        {
          path: ':id(\\d+)?',
          name: 'sale/plan/personal',
          props: true,
          component: () => import(/* webpackChunkName: "plan" */ '@/views/sale/plan/Personal.vue'),
          meta: {
            title: 'Личный план',
            sidebarComponents: ['Rating']
          }
        },
        {
          path: 'progress',
          name: 'sale/plan/progress',
          component: () => import(/* webpackChunkName: "plan_progress" */ '@/views/sale/plan/Progress.vue'),
          meta: {
            title: 'План компании',
            perm: 'sale_settings'
          }
        },
        {
          path: 'progress/edit',
          name: 'sale/plan/progress/edit',
          component: () => import(/* webpackChunkName: "plan_progress" */ '@/views/sale/plan/ProgressEdit.vue'),
          meta: {
            title: 'Редактирование плана сотрудников',
            perm: 'sale_settings'
          }
        }
      ]
    },
    {
      path: 'planning',
      name: 'sale/planning',
      component: () => import(/* webpackChunkName: "sale_planning" */ '@/views/sale/Planning.vue'),
      meta: {
        title: 'Планирование',
        perm: 'sale_plan'
      }
    },
    {
      path: 'settings',
      name: 'sale/settings',
      component: () => import(/* webpackChunkName: "sale_settings" */ '@/views/sale/Settings.vue'),
      meta: {
        title: 'Настройки модуля план',
        perm: 'sale_settings'
      }
    },
    {
      path: 'wizard',
      name: 'sale/wizard',
      component: () => import(/* webpackChunkName: "sale_wizard" */ '@/views/sale/Wizard.vue'),
      meta: {
        title: 'Мастер настройки плана'
      }
    },
    {
      path: 'dashboard',
      name: 'sale/dashboard',
      component: () => import(/* webpackChunkName: "sale_dashboard" */ '@/views/sale/Dashboard.vue'),
      meta: {
        title: 'Отчеты',
        perm: 'sale_dashboard',
        sidebarComponents: ['Dashboard']
      }
    },
    {
      path: 'active-board',
      name: 'sale/active-board',
      component: () => import(/* webpackChunkName: "sale" */ '@/views/sale/ActiveBoard.vue')
    },
    {
      path: 'kpi',
      name: 'sale/kpi',
      component: () => import(/* webpackChunkName: "sale" */ '@/views/sale/Kpi.vue')
    },
    {
      path: 'rating',
      name: 'sale/rating',
      component: () => import(/* webpackChunkName: "sale" */ '@/views/sale/Rating.vue')
    },
    {
      path: 'arena',
      name: 'sale/arena',
      redirect: '/sale/arena/combats/start',
      component: () => import(/* webpackChunkName: "arena" */ '@/views/sale/Arena.vue'),
      children: [
        {
          path: 'quests',
          name: 'sale/arena/quests',
          component: () => import(/* webpackChunkName: "arena" */ '@/views/sale/arena/Quests.vue')
        },
        {
          path: 'combats',
          name: 'sale/arena/combats',
          redirect: '/sale/arena/combats/start',
          component: () => import(/* webpackChunkName: "arena" */ '@/views/sale/arena/Combats.vue'),
          children: [
            {
              path: 'start',
              name: 'sale/arena/combats/start',
              component: () => import(/* webpackChunkName: "arena" */ '@/views/sale/arena/Combats/StartPage.vue')
            },
            {
              path: 'list',
              name: 'sale/arena/combats/list',
              component: () => import(/* webpackChunkName: "arena" */ '@/views/sale/arena/Combats/CombatsList.vue')
            },
            {
              path: 'create',
              name: 'sale/arena/combats/create',
              component: () => import(/* webpackChunkName: "arena" */ '@/views/sale/arena/Combats/CombatCreate.vue')
            }
          ]
        }
      ]
    },
    {
      path: 'reports',
      name: 'sale/reports',
      component: () => import(/* webpackChunkName: "sale" */ '../views/sale/Reports.vue')
    },
    {
      path: 'companies',
      name: 'sale/companies',
      component: () => import(/* webpackChunkName: "sale" */ '../views/sale/Companies.vue')
    },
    {
      path: 'contacts',
      name: 'sale/contacts',
      component: () => import(/* webpackChunkName: "sale" */ '../views/sale/Contacts.vue')
    },
    {
      path: 'sync',
      name: 'sale/sync',
      component: () => import(/* webpackChunkName: "sale_sync" */ '../views/sale/Sync.vue')
    }
  ]
}
