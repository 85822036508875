import Vue from 'vue'
import Vuex from 'vuex'
import Api from '@/common/api.service'
import Jwt from '@/common/jwt.service'

Vue.use(Vuex)

const state = {
  error: null,
  user: null,
  isAuth: false
}

const mutations = {
  SET_ERROR (state, error) {
    state.error = error
  },
  SET_AUTH (state, data) {
    state.isAuth = true
    state.user = data
    state.error = null
    // Jwt.saveToken(current.access_token)
  },
  PURGE_AUTH (state) {
    state.isAuth = false
    state.user = null
    state.error = null
    Jwt.destroyToken()
  }
}

const actions = {
  async logout ({ commit }) {
    await commit('PURGE_AUTH')
  },
  async checkAuth ({ commit }) {
    if (Jwt.getToken()) {
      Api.setHeader()
      try {
        const res = await Api.query('user/current')
        await commit('SET_AUTH', res)
      } catch (err) {
        await commit('PURGE_AUTH')
        await commit('SET_ERROR', 'Wrong authorization')
      }
    } else {
      commit('PURGE_AUTH')
    }
  }
}

const getters = {
  current (state) {
    return state.user
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
