import { APP_CALL } from '@/common/b24.apps'

export default [
  {
    path: '/call',
    name: 'call',
    component: () => import(/* webpackChunkName: "call" */ '@/views/call/Index.vue'),
    meta: {
      app: APP_CALL,
      title: 'Звонки'
    },
    // redirect: '/call/missed',
    children: [
      {
        path: 'missed',
        name: 'call/missed',
        component: () => import(/* webpackChunkName: "call" */ '@/views/call/Missed.vue'),
        meta: {
          title: 'Пропущенные звонки'
        }
      },
      {
        path: 'settings',
        name: 'call/settings',
        component: () => import(/* webpackChunkName: "call" */ '@/views/call/Settings.vue'),
        meta: {
          title: 'Настройки модуля'
        }
      },
      {
        path: 'sync',
        name: 'call/sync',
        component: () => import(/* webpackChunkName: "call_sync" */ '../views/call/Sync.vue')
      }
    ]
  }
]
