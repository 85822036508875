import axios from 'axios'
import qs from 'qs'
import Jwt from '@/common/jwt.service'
import store from '@/store'

// Format nested params correctly
axios.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    // Qs is not included in the Axios package
    return qs.stringify(params, {
      arrayFormat: 'brackets'
      // encode: false
    })
  }

  return config
})

axios.interceptors.response.use(
  response => {
    if (response.status === 401) {
      return store.commit('auth/PURGE_AUTH')
    }
    if (!response || !response.data) {
      return []
    }
    if (response.config.method === 'delete') {
      return true
    }
    if (response.config.method === 'get' && response.data.total !== undefined) {
      return response.data
    }
    return response.data.data
  },
  error => Promise.reject(error.response.data.data.message)
)

function init () {
  axios.defaults.baseURL = '/api/'
}

function setHeader () {
  axios.defaults.headers.common.Authorization = `Bearer ${Jwt.getToken()}`
  axios.defaults.headers.common['X-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone
}

async function query (url, params = {}) {
  return get(url, params)
}

async function get (url, params = {}) {
  return axios.get(url, {
    params
  })
}

async function post (url, params = {}) {
  return axios.post(url, params)
}

async function put (url, params = {}) {
  return axios.put(url, params)
}

async function del (url, params = {}) {
  return axios.delete(url, {
    data: params
  })
}

export default {
  init,
  setHeader,
  query,
  post,
  put,
  delete: del
}
