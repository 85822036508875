import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import { Bus as vuedals } from 'vuedals'
import Panel from '@/components/Panel'

import auth from './auth.module'
import combats from './combats.module'
import sale from './sale'
import integration from './integration'

Vue.use(Vuex)

const getters = {
  isBitrix24 () {
    return window.name && window.name.indexOf('|') !== -1
  }
}

const actions = {
  async openPanel (context, payload) {
    const res = router.matcher.match(payload.path || payload.e.target.pathname)
    const route = res.matched.pop()

    if (route.meta.isPanel) {
      if (payload.e !== undefined) {
        payload.e.preventDefault()
      }

      let title = res.meta.panelTitle || ''
      for (const param in res.params) {
        title = title.replaceAll('{' + param + '}', res.params[param])
      }

      const backurl = window.location.href
      window.history.replaceState({}, document.title, payload.path || payload.e.target.pathname)

      await vuedals.$emit('new', {
        title: title || document.title,
        component: Panel,
        props: { component: route.components.default, params: res.params },
        size: route.meta.panelSize || 'md',
        onDismiss: () => {
          window.history.replaceState({}, document.title, backurl)
          const panels = Array.prototype.filter.call(document.getElementsByClassName('vuedal'), el => el.classList.contains('disabled'))
          for (let i = 0; i < panels.length; i++) {
            panels[panels.length - 1 - i].style.opacity = 1
            panels[panels.length - 1 - i].style.right = i * 100 + 'px'
          }
        }
      })

      const panels = Array.prototype.filter.call(document.getElementsByClassName('vuedal'), el => el.classList.contains('disabled'))
      for (let i = 0; i < panels.length; i++) {
        panels[panels.length - 1 - i].style.opacity = 1
        panels[panels.length - 1 - i].style.right = (i + 1) * 100 + 'px'
      }
    }
  },
  async closePanel (context, payload) {
    await vuedals.$emit('dismiss')
  }
}

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  getters,
  actions,
  modules: {
    auth,
    combats,
    sale,
    integration
  }
})
